<template>
  <div>
    <div v-if="districts && districts.length > 0">
      <vue-tabs class="tabs--district" active-tab-color="#ffffff" centered>
        <v-tab
          :title="$t(`directory.district.${listingType}`)"
          v-for="listingType in listingTypes"
          :key="listingType"
          class="tab--content"
        >
          <div class="row">
            <div class="col-6 p-1" v-for="district in districts" :key="district.id">
              >
              <router-link :to="districtPath(listingType, district)">{{
                district.name
              }}</router-link>
            </div>
          </div>
        </v-tab>
      </vue-tabs>
    </div>
  </div>
</template>

<script>
import { VTab, VueTabs } from 'vue-nav-tabs';
import 'vue-nav-tabs/themes/vue-tabs.css';
import { mapState } from 'vuex';
export default {
  components: {
    VueTabs,
    VTab,
  },
  computed: {
    ...mapState({
      listingTypes: state => state.global.listingTypes,
      districts: state => state.v2.directory.districts,
    }),
  },
  methods: {
    districtPath(listingType, district) {
      let propertyType = this.$route.params.propertyType;
      if (district && district.name) {
        return encodeURI(
          '/' +
            (listingType === 'R' ? 'rent' : 'buy') +
            '/search/' +
            propertyType +
            '/' +
            district.name
              .replace('-', '__')
              .split(' ')
              .join('-')
              .replace(/\//g, '') +
            '?category=district&category_filter_id=' +
            district.id,
        );
      } else {
        return encodeURI('/' + (listingType === 'R' ? 'rent' : 'buy') + '/search/' + propertyType);
      }
    },
  },
};
</script>
